import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ThemeToggle from "./ThemeToggle";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as userApi from '../api/user'
import * as verifyCodeApi from '../api/verifyCode'
import { DISTRIBUTOR_USER_ID, REDDIRECT_URL, TOKEN } from "../constants";
import { useTranslation } from "react-i18next";
// import { message } from "react-message-popup";

import { shapes, thumbs } from "../constants/icons";

import {
  AlipayCircleOutlined,
  LockOutlined,
  MobileOutlined,
  TaobaoCircleOutlined,
  UserOutlined,
  WeiboCircleOutlined,
} from '@ant-design/icons';
import {
  createIntl,
  enUSIntl,
  LoginForm,
  ProConfigProvider,
  ProFormCaptcha,
  ProFormCheckbox,
  ProFormText,
  setAlpha,
  zhCNIntl,
} from '@ant-design/pro-components';
import { Space, Tabs, message, theme } from 'antd';
import { isValidPhone } from "../utils/func";



const LoginComponent = ({
  favicon,
  site_name,
  description
}) => {

  const { t, i18n } = useTranslation()

  const { token } = theme.useToken();
  const [userLoginState,setUserLoginState] = useState({})
  const [loginType, setLoginType] = useState('password');

  const [timing,setTiming] = useState(false)
  const [username,setUsername] = useState('')
  const [password,setPassword] = useState('')
  const [phone,setPhone] = useState('')
  const [autoLogin,setAutoLogin] = useState(true)
  const [queryParameters] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const username = queryParameters.get("username")
    console.log('login init username is', username)
      if( username ) {
        setUsername(username)
      }
  },[])

  async function getCaptcha() {
    const param = {
      behavior: 'login',
      receiver: phone,
      channel_type: 'sms'
    }
    if(!phone) {
      message.error(t("enter.your.phone"))
      return; 
    }
    
    const response = await verifyCodeApi.sendCode(param)
    console.log('send code response', response)
    if(response && response.code === 0) {
      message.success(t("get.captcha.success"))
    } else {
      message.error(response?.msg)
    }
  }

  const handleSubmit = async (values) => {
    console.log('value collected ->', {
      ...values,
      autoLogin: autoLogin,
    });

    const payload = {
      ...values,
      login_type: loginType,
      distributor_user_id:localStorage.getItem(DISTRIBUTOR_USER_ID) ? localStorage.getItem(DISTRIBUTOR_USER_ID) : 0,
      channel: "web",
    }

    const response = await userApi.login(payload)

    if(response && response.code === 0) {
      onLoginSuccess(response.result)
    } else {
      message.error(response?.msg)
    }
  }

  function onLoginSuccess(result) {
      message.success(t("login.success"))
      localStorage.setItem(TOKEN, result?.access_token)
      const redirectUrl = localStorage.getItem(REDDIRECT_URL) ? localStorage.getItem(REDDIRECT_URL) : "/"
      console.log("redirect url is--------####", redirectUrl)
      navigate(redirectUrl, { replace: true })
  }

  function getIntl() 
  {
    let intl = enUSIntl;
    if(i18n.language == "zh") {
      intl = zhCNIntl;
    }
    // 其他语言继续添加
    return intl;
  }

  function renderForm() {
    return (
      <ProConfigProvider 
        hashed={false} 
        intl={getIntl()}>
        <div style={{ backgroundColor: token.colorBgContainer }}>
          <LoginForm
            logo={favicon}
            title={site_name}
            subTitle={description}

            actions={
              <Space>
                {t("other.login.way")}
                {/* <AlipayCircleOutlined style={iconStyles} />
                <TaobaoCircleOutlined style={iconStyles} />
                <WeiboCircleOutlined style={iconStyles} /> */}
                {/* {localStorage.getItem(DISTRIBUTOR_USER_ID) ? localStorage.getItem(DISTRIBUTOR_USER_ID) : '-'} */}
              </Space>
            }
            onFinish={async values => {
              await handleSubmit(values)
            }}
          >
            <Tabs
              centered
              activeKey={loginType}
              onChange={(activeKey) => setLoginType(activeKey)}
            >
              <Tabs.TabPane key={'password'} tab={t("email.login")} />
              <Tabs.TabPane key={'phone'} tab={t("phone.login")} />
            </Tabs>
            {loginType === 'password' && (
              <>
                <ProFormText
                  name="username"
                  fieldProps={{
                    size: 'large',
                    prefix: <UserOutlined className={'prefixIcon'} />,
                  }}
                  placeholder={`${t("your.email")}: info@mail.com`}
                  rules={[
                    {
                      required: true,
                      message: t("your.email.here"),
                    },
                  ]}
                />
                <ProFormText.Password
                  name="password"
                  fieldProps={{
                    size: 'large',
                    prefix: <LockOutlined className={'prefixIcon'} />,
                    strengthText:t("password.strength.text"),
                    statusRender: (value) => {
                      const getStatus = () => {
                        if (value && value.length > 12) {
                          return 'ok';
                        }
                        if (value && value.length > 6) {
                          return 'pass';
                        }
                        return 'poor';
                      };
                      const status = getStatus();
                      if (status === 'pass') {
                        return (
                          <div style={{ color: token.colorWarning }}>
                            {t("pasword.strength.pass")}
                          </div>
                        );
                      }
                      if (status === 'ok') {
                        return (
                          <div style={{ color: token.colorSuccess }}>
                            {t("pasword.strength.ok")}
                          </div>
                        );
                      }
                      return (
                        <div style={{ color: token.colorError }}> {t("pasword.strength.poor")}</div>
                      );
                    },
                  }}
                  placeholder={t("enter.your.password")}
                  rules={[
                    {
                      required: true,
                      message: t("enter.your.password"),
                    },
                  ]}
                />
              </>
            )}
            {loginType === 'phone' && (
              <>
                <ProFormText
                  fieldProps={{
                    size: 'large',
                    prefix: <MobileOutlined className={'prefixIcon'} />,
                  }}
                  name="phone"
                  placeholder={t("enter.your.phone")}
                  rules={[
                    {
                      required: true,
                      message: t("enter.your.phone"),
                    },
                    {
                      pattern: /^1\d{10}$/,
                      message: t("phone.format.error"),
                    },
                  ]}
                  onChange={e => {
                    setPhone(e.target.value)
                  }}
                  
                />
                <ProFormCaptcha
                  fieldProps={{
                    size: 'large',
                    prefix: <LockOutlined className={'prefixIcon'} />,
                  }}
                  captchaProps={{
                    size: 'large',
                    // disabled: phone == "" || !isValidPhone(phone) || timing
                  }}
                  placeholder={t("enter.captcha")}

                  captchaTextRender={(timing, count) => {
                  
                    if (timing) {
                      return `${count} ${t("get.captcha")}`;
                    }
                    return t("get.captcha");
                  }}
                  name="captcha"
                  rules={[
                    {
                      required: true,
                      message: t("get.captcha"),
                    },
                  ]}
                  
                  onGetCaptcha={async () => {
                    getCaptcha()
                    // const response = await 
                    // message.success(t("get.captcha.success.captcha.is",{ captcha: 1234 }));
                  }}
                />
              </>
            )}
            <div
              style={{
                marginBlockEnd: 24,
              }}
            >
              <ProFormCheckbox noStyle name="autoLogin">
                
                {t("auto.login")}
              </ProFormCheckbox>
              <a
                style={{
                  float: 'right',
                }}
              >
                {t("forget.password")}
              </a>
            </div>
          </LoginForm>
        </div>
      </ProConfigProvider>
    );
  }



  return (
    <>
      {/* ================================== Account Page Start =========================== */}
      <section className="account d-flex">
        <img
          src={thumbs.account_img}
          alt=""
          className="account__img"
        />
        <div className="account__left d-md-flex d-none flx-align section-bg position-relative z-index-1 overflow-hidden">
          <img
            src={shapes.curve_pattern7}
            alt=""
            className="position-absolute end-0 top-0 z-index--1 h-100"
          />
          <div className="account-thumb">
            <img src={ thumbs.banner_img} alt="" />
            <div className="statistics animation bg-main text-center">
              <h5 className="statistics__amount text-white">50k</h5>
              <span className="statistics__text text-white font-14">
                {t("customers")}
              </span>
            </div>
          </div>
        </div>
        <div className="account__right padding-y-120 flx-align">
          <div className="dark-light-mode">
            {/* Light Dark Mode */}
            <ThemeToggle />
          </div>
          
          <div className="account-content">
            {/* <Link to="/" className="logo mb-64">
              <img
                src={logo}
                alt=""
                className="white-version"
              />
              <img
                src={logo}
                alt=""
                className="dark-version"
              />
            </Link> */}
            {/* <h4 className="account-content__title mb-48 text-capitalize">
              {t("welcome.back")}!
            </h4> */}

            {renderForm()}
     
            {/* <form action="#"> */}
              <div className="row gy-4">
                {/* <div className="col-12">
                  <label
                    htmlFor="email"
                    className="form-label mb-2 font-18 font-heading fw-600"
                  >
                    {t("email")}
                  </label>
                  <div className="position-relative">
                    <input
                      type="email"
                      className="common-input common-input--bg common-input--withIcon"
                      id="email"
                      placeholder="infoname@mail.com"
                      value={username}
                      onChange={e => setUsername(e.target.value)}
                    />
                    <span className="input-icon">
                      <img src="../assets/images/icons/envelope-icon.svg" alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <label
                    htmlFor="your-password"
                    className="form-label mb-2 font-18 font-heading fw-600"
                  >
                    {t("password")}
                  </label>
                  <div className="position-relative">
                    <input
                      type="password"
                      className="common-input common-input--bg common-input--withIcon"
                      id="your-password"
                      placeholder={t("please.enter.your.password")}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                    <span
                      className="input-icon toggle-password cursor-pointer"
                      id="#your-password"
                    >
                      <img src="../assets/images/icons/lock-icon.svg" alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="flx-between gap-1">
                    <div className="common-check my-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="checkbox"
                        id="keepMe"
                      />
                      <label
                        className="form-check-label mb-0 fw-400 font-14 text-body"
                        htmlFor="keepMe"
                      >
                        {t("keep.me.signed.in")}
                      </label>
                    </div>
                    <Link
                      to="#"
                      className="forgot-password text-decoration-underline text-main text-poppins font-14"
                    >
                      {t("forgot.password")}?
                    </Link>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-main btn-lg w-100 pill"
                    onClick={() => onSubmit()}
                  >
                    {" "}
                    {t("sign.in")}
                  </button>
                </div> */}
                {/* <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-outline-light btn-lg-icon btn-lg w-100 pill"
                  >
                    <span className="icon icon-left">
                      <img src="../assets/images/icons/google.svg" alt="" />
                    </span>
                    Sign in with google
                  </button>
                </div> */}
                {/* <div className="col-sm-12 mb-0">
                  <div className="have-account">
                    <p className="text font-14">
                      {t("new.to.the.market")}?{" "}
                      <Link
                        className="link text-main text-decoration-underline fw-500"
                        to="/register"
                      >
                        {t("sign.up")}
                      </Link>
                    </p>
                  </div>
                </div> */}
              </div>
            {/* </form> */}
          </div>
        </div>
      </section>
      {/* ================================== Account Page End =========================== */}
    </>
  );
};

export default LoginComponent;
