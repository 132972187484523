import React from "react";
import Preloader from "../../helper/Preloader";
import MasterLayout from "../../layout/MasterLayout";
import DashboardInner from "../../components/DashboardInner";
import SEO from "../../components/SEO";
import { t } from "i18next";
const DashboardPage = () => {

  return (

    <>
      <MasterLayout>

        <SEO
          title={t("dashboard")}
        />

        {/* Preloader */}
        <Preloader />

        {/* DashboardInner */}
        {/* <DashboardInner /> */}

      </MasterLayout>

    </>
  );
};

export default DashboardPage;
